import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import NeomorphButtonBase from "components/NeomorphButtonBase/NeomorphButtonBase";
import Spacer from "components/Spacer/Spacer";
import { FC, useEffect, useState } from "react";
import { useScreenType } from "utils/constants/UseScreenType";
import { baseStyles } from "utils/constants/baseStyles";
import { Ghana } from "utils/fakedata";
import { OperatorItem as OperatorItemType } from "utils/interfaces";
import { reversedIso3, truncate } from "utils/utils";
import { ButtonBase } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IProps {
  item?: OperatorItemType;
  onClickDetails?: Function;
}

const OperatorItem: FC<IProps> = ({ item, onClickDetails }) => {
  const { isTab } = useScreenType();
  const { t } = useTranslation();
  const [operatorLogo, setOperatorLogo] = useState<any>(null);
  const [country, setCountry] = useState<any>(null);

  useEffect(() => {
    if (item?.id) {
      setOperatorLogo(`${process.env.REACT_APP_API_URL}/${item?.logo?.path}`);
      // setCountry(`${process.env.REACT_APP_API_URL}/${item?.logo?.path}`);
    }
  }, [item]);

  return (
    <ButtonBase
      onClick={() => {
        onClickDetails?.(item);
      }}
    >
      <NeomorphContainer width={isTab ? "31%" : 280} height={180}>
        <div style={{ padding: 10 }}>
          <section
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <NeomorphButtonBase
                onClick={() => onClickDetails?.(item)}
                height={37}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    height={30}
                    width={35}
                    style={{ padding: 3 }}
                    alt="country"
                    src={`https://flagcdn.com/w80/${reversedIso3[
                      item?.country?.code ?? "AF"
                    ]?.toLowerCase()}.png`}
                  />
                  <p
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                      display: "inline-block",
                      textTransform: "none",
                      color: baseStyles.text.default,
                    }}
                  >
                    {truncate(item?.country?.name, 12) ?? ""}
                  </p>
                </div>
              </NeomorphButtonBase>
            </div>
            <div style={{ display: "flex" }}>
              <NeomorphButtonBase onClick={() => {}} height={37}>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "none",
                    color: item?.active
                      ? baseStyles.color.primaire
                      : baseStyles.color.quaternaire,
                  }}
                >
                  {item?.active ? t('input.active') : t('input.noActive')}
                </p>
              </NeomorphButtonBase>
            </div>
          </section>

          <section
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 20,
            }}
          >
            <img
              height={30}
              style={{ marginTop: 8 }}
              alt="operator-logo"
              src={operatorLogo}
            />

            <p
              style={{
                fontSize: 12,
                fontWeight: 500,
                display: "inline-block",
                textTransform: "none",
                color: baseStyles.text.default,
                marginTop: 10,
              }}
            >
              {item?.name}
            </p>

            <Spacer height={20} />
          </section>
        </div>
      </NeomorphContainer>
    </ButtonBase>
  );
};

export default OperatorItem;
