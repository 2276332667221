import { Avatar } from "@mui/material";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import Spacer from "components/Spacer/Spacer";
import React, { FC } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import { Benin, orange } from "utils/fakedata";
import { ITransaction } from "utils/interfaces";
import { reversedIso3 } from "utils/utils";
interface IProps {
  operator: string;
  operatorLogo: string;
  countryCode: string;
  email: string;
  fullName: string;
  countryName?: string;
}

const CardDetails: FC<IProps> = ({
  operator,
  operatorLogo,
  countryCode,
  email,
  fullName,
  countryName,
}) => {
  return (
    <NeomorphContainer style={{ height: "auto", padding: 20 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <NeomorphContainer style={{ height: "38px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: 5 }}>
              <img
                src={`https://flagcdn.com/w80/${reversedIso3[
                  countryCode
                ]?.toLowerCase()}.png`}
                style={{ height: 20 }}
              />
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  color: baseStyles.text.default,
                }}
              >
                {`${countryName}`}
              </p>
            </div>
            <div style={{ display: "flex", gap: 5 }}>
              <img height={15} alt="logo-medium" src={operatorLogo} />
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  color: baseStyles.text.default,
                }}
              >
                {operator ?? "Non défini"}
              </p>
            </div>
          </div>
        </NeomorphContainer>
        <Spacer height={40} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NeomorphContainer
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 50,
              background: "white",
            }}
            width={68}
            height={65}
          >
            <div></div>
            {/* <Avatar
                src={profile}
                sx={{ width: 60, height: 60 }}
                alt="user-avatar"
              /> */}
          </NeomorphContainer>
          <Spacer height={20} />
          <p
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: baseStyles.text.black,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              textAlign: "center",
            }}
          >
            {`${fullName}`}
          </p>
          <p
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: baseStyles.text.default,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              textAlign: "center",
            }}
          >
            {`${email}`}
          </p>
        </div>
      </div>
    </NeomorphContainer>
  );
};

export default CardDetails;
