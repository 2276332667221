import { Avatar, ButtonBase } from "@mui/material";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import NeomorphButtonBase from "components/NeomorphButtonBase/NeomorphButtonBase";
import Spacer from "components/Spacer/Spacer";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useScreenType } from "utils/constants/UseScreenType";
import { baseStyles } from "utils/constants/baseStyles";

interface IProps {
  name?: string;
  role?: string;
  email?: string;
  profile?: string;
  isActive?: number;
  onClickDetails?: Function;
}

const UserItem: FC<IProps> = ({
  name,
  email,
  profile,
  role,
  onClickDetails,
  isActive,
}) => {
  const { isTab } = useScreenType();
  const { t } = useTranslation();
  return (
    <ButtonBase
      onClick={() => {
        onClickDetails?.();
      }}
    >
      <NeomorphContainer width={isTab ? "31%" : 280} height={214}>
        <div style={{ padding: 10 }}>
          <section
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <div style={{ display: "flex" }}>
              <NeomorphButtonBase
                disabled
                onClick={() => onClickDetails?.()}
                height={37}
              >
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "none",
                    color: baseStyles.text.default,
                  }}
                >
                  {role ?? t('input.undefined')}
                </p>
              </NeomorphButtonBase>
            </div>

            <div style={{ display: "flex" }}>
              <NeomorphButtonBase disabled onClick={() => {}} height={37}>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    textTransform: "none",
                    color: isActive
                      ? baseStyles.color.primaire
                      : baseStyles.color.quaternaire,
                  }}
                >
                  {isActive ? t('input.active') : t('input.noActive')}
                </p>
              </NeomorphButtonBase>
            </div>
          </section>

          <section
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 20,
            }}
          >
            <NeomorphContainer
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 50,
                background: "white",
              }}
              width={68}
              height={65}
            >
              <Avatar
                src={profile}
                sx={{ width: 60, height: 60 }}
                alt="user-avatar"
              />
            </NeomorphContainer>
            <Spacer height={20} />
            <p
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: baseStyles.text.black,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
                textAlign: "center",
              }}
            >
              {name && name?.length > 0 ? name : "Non défini"}
            </p>
            <p
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: baseStyles.text.default,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
                textAlign: "center",
              }}
            >
              {email ?? ""}
            </p>
          </section>
        </div>
      </NeomorphContainer>
    </ButtonBase>
  );
};

export default UserItem;
