import logo from "assets/images/logo-real-size-tm.webp";
import { FC } from "react";

interface IProps {
  height?: number;
  width?: number;
}

export const LogoTCash: FC<IProps> = ({ height, width }) => {
  return (
    <img
      height={height ?? 145}
      width={width ?? 195}
      alt="logo-medium"
      src={logo}
    />
  );
};
